'use client';

import isEqual from 'lodash/isEqual';
import { useParams } from 'next/navigation';
import { FC, useMemo, useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import { SelectChangeEvent } from '@mui/material/Select';

import { ActionTypes, useAppContext } from 'src/contexts/AppContext';
import {
  useGetProducts,
  useGetProductsCategories,
} from 'src/api/product';

import ProductFilters from 'src/sections/products/product-filters';

import { ComponentTypeProps } from 'src/types/page-generator';
import { Ib2bTableFilter, Ib2bTableFilterValue } from 'src/types/b2b';

import { useRouter } from '../../../../routes/hooks';
import { ICategoryItem } from '../../../../types/product';
import { useDebounce } from '../../../../hooks/use-debounce';
import { useResponsive } from '../../../../hooks/use-responsive';
import { AlternativeProducts } from '../../../products/alternative-products';
import { NotAlternativeProducts } from '../../../products/not-alternative-products';

const Products: FC<ComponentTypeProps> = ({ block }) => {
  // const [products, setProducts] = useState<Ib2bProduct[]>([]);
  const [open, setOpen] = useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  const { dispatch, state } = useAppContext();
  const LIMIT = block?.settings?.enableRotation
    ? block?.settings?.rotationLimit
    : block?.settings?.limit || 8;
  const { communicationWithProducts } = state;
  const isMobile = useResponsive('down', 'sm');
  const router = useRouter();
  const [page, setPage] = useState(1);
  const { id, title } = useParams();
  const { smbAccount } = state;
  const combinedBlock: any = block?.products || [];
  const [checked, setChecked] = useState(false);
  const localCategories =
    block?.settings?.enableCategoryFilter && block?.settings?.categories
      ? block?.settings?.categories
      : [];
  const localProductsTags =
    block?.settings?.enableTagsFilter && block?.settings?.productsTags
      ? block?.settings?.productsTags
      : [];
  // const { products: localProducts } = useGetProducts({
  //   smbAccount: state.smbAccount.id,
  // });
  const [mainProducts, setCombinedBlock] = useState([]);

  const { productsCategories } = useGetProductsCategories({});
  const actionButtons: any = block?.actionButtons || [];

  useEffect(() => {
    if (block?.openPriceProducts) {
      const payload = block?.openPriceProducts;
      dispatch({ type: ActionTypes.SET_OPEN_PRICE_PRODUCTS, payload });
      dispatch({
        type: ActionTypes.SET_OPEN_PRICE_PRODUCTS_FROM_BUILDER,
        payload,
      });
    }
  }, [block?.openPriceProducts]);


  useEffect(() => {
    const { hash } = window.location;
    if (hash) {
      const element = document.getElementById(hash.replace('#', ''));
      if (element) {
        window.location.href = `#${element}`;
      }
    }
  }, [router]);

  let priceType: any = '';
  const { enableSwitch, enableCategoryFilter, enableTagsFilter } = block?.settings || {};

  let priceChecked = '';

  if (enableSwitch) {
    if (checked) {
      priceChecked = 'business_price';
    } else if (enableCategoryFilter) {
      priceChecked = '';
    } else {
      priceChecked = 'price';
    }
  }

  if (!enableSwitch && enableCategoryFilter && enableTagsFilter) {
    priceType = {};
  } else {
    priceType = { priceType: priceChecked };
  }

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  // useEffect(() => {
  //   const updatedCombinedBlock = combinedBlock?.map((item: any) => {
  //     let updatedItem = { ...item };
  //     localProducts?.forEach((product: Ib2bProduct) => {
  //       if (updatedItem.id === product.id) {
  //         updatedItem = {
  //           ...updatedItem,
  //           prices: product.prices,
  //           categories: product.categories,
  //         };
  //       }
  //     });
  //     return updatedItem;
  //   });
  //   setCombinedBlock(updatedCombinedBlock);
  // }, [localProducts]);

  useEffect(() => {
    setCombinedBlock(combinedBlock);
  }, []);

  const defaultFilters: Ib2bTableFilter = {
    name: '',
    type: [],
    category: [],
    area: [],
    price: [0, 3000],
    search: '',
  };
  const [filters, setFilters] = useState(defaultFilters);

  const merchantTagIds: string[] = [];
  const productTagIds: string[] = [];
  const categoryIds: string[] = [];
  // filters.type.forEach((fType: string) =>
  //   merchantTagIds.push(String(merchantsTags.find((mt: ITag) => mt.name === fType)?.id))
  // );
  filters.type.forEach((a: string) =>
    merchantTagIds.push(String(state?.location.find((pt: any) => pt.value === a)?.id)),
  );
  // filters.area.forEach((a: string) =>
  //   productTagIds.push(String(productsTags.find((pt: ITag) => pt.name === a)?.id))
  // );
  filters.area.forEach((a: string) =>
    productTagIds.push(String(state?.kosher.find((mt: any) => mt.value === a)?.id)),
  );
  filters.category.forEach((c: string) =>
    categoryIds.push(String(productsCategories.find((cat: ICategoryItem) => cat.title === c)?.id)),
  );
  const currentCategories: string[] = [];
  localCategories.forEach((a: ICategoryItem) => {
    currentCategories.push(String(a.value));
  });

  const currentProductsTags: string[] = [];
  if (!communicationWithProducts) {
    localCategories.forEach((a: ICategoryItem) => {
      currentCategories.push(String(a.value));
    });
  }

  if (communicationWithProducts) {
    currentCategories.push(state?.activeCategory && state?.activeCategory[0]?.id);
  }
  const categories = useMemo(() => (block?.settings?.enableCategoryFilter && block?.settings?.categories ? currentCategories : categoryIds), [block?.settings?.enableCategoryFilter, block?.settings?.categories, currentCategories, categoryIds]);
  // const categories = categoryIds?.length ? categoryIds : currentCategories;

  // const tags = productTagIds?.length ? productTagIds : currentProductsTags;
  const debouncedSearch = useDebounce(filters.name);
  const debouncedMinPrice = useDebounce(filters.price[0]);
  const debouncedMaxPrice = useDebounce(filters.price[1]);
  const baseProps = useMemo(() => ({
    offset: (page - 1) * LIMIT || 0,
    search: debouncedSearch,
    minPrice: debouncedMinPrice,
    maxPrice: debouncedMaxPrice,
    categoryIds: title === 'category' ? [id] : categories,
    merchantId: title === 'merchant' ? id : '',
    merchantTagIds: merchantTagIds || [],
    productTagIds: productTagIds || [],
    tagsIds: localProductsTags,
    ...priceType,
  }), [page,
    debouncedSearch,
    debouncedMinPrice,
    debouncedMaxPrice,
    title,
    categories,
    merchantTagIds,
    productTagIds,
    localProductsTags,
    priceType]);

  const props = useMemo(() =>
    block?.settings?.isAlternativeDesign && !block?.settings?.partialSize
      ? baseProps
      : {
        ...baseProps,
        limit: LIMIT,
      }, [block?.settings,
    debouncedSearch,
    debouncedMinPrice,
    debouncedMaxPrice,
    categories,
    merchantTagIds,
    productTagIds,
    localProductsTags,
    priceType]);

  const { products, totalCount } = useGetProducts({ ...props });
  // const products: any = [];
  const canReset = !isEqual(defaultFilters, filters);

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    const { hash } = window.location;

    if (hash && isMounted) {
      const element = document.getElementById(hash.replace('#', ''));
      if (element) {
        window.location.href = `#${element.id}`;
      }
    }
  }, [router, isMounted, products, mainProducts]);

  const handleFilters = (name: string, filterValue: Ib2bTableFilterValue) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: filterValue,
    }));
  };
  const handleResetCategory = () => {
    setFilters((prevState) => ({
      ...prevState,
      category: [],
    }));
  };

  const handleResetType = () => {
    setFilters((prevState) => ({
      ...prevState,
      type: [],
    }));
  };

  const handleResetArea = () => {
    setFilters((prevState) => ({
      ...prevState,
      area: [],
    }));
  };

  useEffect(() => {
    setPage(1);
  }, [filters, state?.activeCategory]);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const filterView = (
    <>
      {block?.settings?.enableFilter &&
        !block?.settings?.customMode &&
        !block?.settings?.enableCategoryFilter &&
        !block?.settings?.enableTagsFilter && (
          <ProductFilters
            filters={filters}
            block={block}
            onFilters={handleFilters}
            canReset={canReset}
            handleResetCategory={handleResetCategory}
            handleResetType={handleResetType}
            handleResetArea={handleResetArea}
            // typeOption={state.productsTags.map((mTag: ITag) => mTag.name)}
            // areaOption={state.merchantsTags.map((mTag: ITag) => mTag.name)}
            typeOption={state?.kosher || []}
            areaOption={state?.location || []}
            categoryOption={productsCategories}
          />
        )}
    </>
  );


  const handleFilterCategory = (event: SelectChangeEvent<string[]>) => {
    handleFilters(
      'category',
      typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value,
    );
  };

  return (
    <Box
      sx={{
        display: isMobile && block?.settings?.disableOnMobile ? 'none' : 'block',
      }}
      id={block?.trigger as string || ''}
    >
      {!block?.settings?.isAlternativeDesign ? (
        <>
          <NotAlternativeProducts
            block={block}
            dataFiltered={products}
            checked={checked}
            handleChange={handleChange}
            title={title}
            open={open}
            toggleDrawer={toggleDrawer}
            filterView={filterView}
            dataFilteredCustom={mainProducts} />
          <br />
          {!block?.settings?.customMode && products?.length !== 0 && (
            <Stack width={1} direction="row" alignItems="center">
              <Pagination
                onChange={handleChangePage}
                page={page}
                count={Math.ceil(totalCount / LIMIT) || 1}
                color="primary"
                sx={{ margin: '0 auto' }}
              />
            </Stack>
          )}
          <br />
        </>
      ) : (
        <AlternativeProducts
          block={block}
          dataFiltered={products}
          open={open}
          toggleDrawer={toggleDrawer}
          filterView={filterView}
          dataFilteredCustom={mainProducts}
          actionButtons={actionButtons}
          handleFilterCategory={handleFilterCategory}
        />
      )}
      {/* <Box id={block?.trigger as string || ''} /> */}
    </Box>
  );
};

export default Products;
