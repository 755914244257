'use client';

import { useMemo, useState, useEffect } from 'react';
import { useRouter, usePathname } from 'next/navigation';

import { Box, Tab, Tabs, useTheme } from '@mui/material';

import { ActionTypes, useAppContext } from 'src/contexts/AppContext';
import { useAuthContext } from 'src/auth/hooks';

import HomeMenuIcon from 'src/assets/icons/city-people/footer/home-menu-icon';
import WalletMenuIcon from 'src/assets/icons/city-people/footer/wallet-menu-icon';
import HistoryMenuIcon from 'src/assets/icons/city-people/footer/history-menu-icon';
import QuestionMenuIcon from 'src/assets/icons/city-people/footer/question-menu-icon';

import Image from 'src/components/image';

import { ComponentTypeProps } from 'src/types/page-generator';

import { hexToRgb } from '../../../../utils/hexToRgb';
import { getContentValueFromProps } from '../../utils';

export type LinkItemType = {
  title: string;
  path: string;
  imageUrl?: string;
} & Record<string, string>;

const DEFAULT_FOOTER_MENU = [
  { title: 'בית', path: '#' },
  { title: 'ארנק', path: '#' },
  { title: 'היסטוריה', path: '#' },
  { title: 'עזרה', path: '#' },
];

// ----------------------------------------------------------------------

const NAME_LOGIN_POPUP = 'login';

const getTabPos = (path: string, isClient: boolean, paths?: string[]) => {
  // if (Array.isArray(paths) && paths.length) {
  //   const idx = paths.findIndex((p: string) => p === path);
  //
  //   if (idx >= 0) return idx;
  // }

  switch (path) {
    case 'supplier-history/':
    case 'wallet/':
    case 'alt-wallet/':
      return 0;
    case 'history/':
      return 1;
    case 'contact/':
      return isClient ? 3 : 2;
    case 'faq/':
      return isClient ? 2 : 1;
    default:
      return 0;
  }
};

export default function FooterCityPeople({ block }: ComponentTypeProps) {
  const paths = usePathname().split('/he/');
  const path = paths.pop() || '';
  const { state, dispatch } = useAppContext();
  const { isAuthenticated } = useAuthContext();
  const getFieldValue = getContentValueFromProps(block);
  const router = useRouter();
  const isClient = !state?.customer?.merchants?.length;
  // const isClient = true;

  // === Content Values ===
  const footerMenuLinks: LinkItemType[] = useMemo(
    () => getFieldValue('footerMenuLinks') || [],
    [block?.footerMenuLinks]
  );
  const footerPaths = useMemo(
    () => footerMenuLinks.map((i: LinkItemType) => i.path.replace('/', '')),
    [footerMenuLinks]
  );

  // === Settings Values ====
  const backgroundColor = useMemo(
    () => getFieldValue('settings.backgroundColor'),
    [block?.settings.backgroundColor]
  );

  const fontSizeLabel = useMemo(
    () => getFieldValue('settings.fontSizeLabel') ?? '14px',
    [block?.settings.fontSizeLabel]
  );
  const iconSize = useMemo(
    () => getFieldValue('settings.iconSize') ?? '36px',
    [block?.settings.iconSize]
  );

  const hasBorderRadius = useMemo(
    () => getFieldValue('settings.hasBorderRadius') ?? true,
    [block?.settings.hasBorderRadius]
  );

  const textColor = useMemo(() => getFieldValue('settings.textColor'), [block?.settings.textColor]);

  const activeButtonView = useMemo(
    () => getFieldValue('settings.activeButtonView'),
    [block?.settings.activeButtonView]
  );

  const theme = useTheme();

  const textColorArray = textColor.split('.');
  const textColorInactive = useMemo(() => {
    if (!backgroundColor) return '';

    const palette: { [key: string]: any } = theme.palette;
    let textColorRgb;

    if (textColorArray) {
      textColorRgb = hexToRgb(palette[textColorArray[0]][textColorArray[1]]);
    }

    if (textColorRgb) {
      return `rgba(${textColorRgb.r}, ${textColorRgb.g}, ${textColorRgb.b}, 0.8)`;
    }

    if (backgroundColor) {
      const bgColorArray = backgroundColor.split('.');
      return theme.palette.getContrastText(palette[bgColorArray[0]][bgColorArray[1]]);
    }

    return 'rgba(0, 0, 0, 0.8)';
  }, [textColor, backgroundColor]);

  // const path = usePathname();

  // const userType = path?.includes('client') ? 'client' : 'supplier';

  const [value, setValue] = useState(getTabPos(path, isClient));

  // const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  //   console.log(newValue)
  //   setValue(newValue);
  // };

  // temporary solution
  useEffect(() => {
    setValue(getTabPos(path, isClient, footerPaths));
  }, [path]);

  const getDefaultIconByTitle = (title: string) => {
    switch (title) {
      case 'בית':
        return <HomeMenuIcon width={iconSize} height={iconSize} />;
      case 'ארנק':
        return <WalletMenuIcon width={iconSize} height={iconSize} />;
      case 'היסטוריה':
        return <HistoryMenuIcon width={iconSize} height={iconSize} />;
      case 'עזרה':
        return <QuestionMenuIcon width={iconSize} height={iconSize} />;
      default:
        return <HomeMenuIcon width={iconSize} height={iconSize} />;
    }
  };

  const getIconElementByURL = (url: string) => (
    <Box width={iconSize} height={iconSize}>
      <Image
        src={url}
        sx={{ width: '100%', height: '100%', objectFit: 'contain' }}
        alt="Menu Item"
      />
    </Box>
  );
  let lineView = 'none';

  if (typeof window !== 'undefined') {
    lineView = !value && window.location.pathname === '/he/' ? 'none' : 'block';
  };
  const boxView = 'none';
  const displayView = activeButtonView === 'line' ? lineView : boxView;

  return (
    <Box
      mt={0}
      sx={{
        zIndex: 999,
        width: { xs: '100%', sm: '393px' },
        backgroundColor,
        borderTopLeftRadius: hasBorderRadius ? 20 : 0,
        borderTopRightRadius: hasBorderRadius ? 20 : 0,
        position: 'fixed',
        right: 0,
        left: 0,
        bottom: 0,
        margin: '0 auto',
      }}
      py={1}
      px={3}
    >
      <Tabs
        value={value}
        // onChange={handleChange}
        centered
        variant="fullWidth"
        sx={{
          px: 1,
          width: '100%',
          justifyContent: 'flex-end',
          display: 'flex',
          color: textColor,
          '.MuiTab-root:not(.Mui-selected)': {
            color: textColorInactive,
          },
          '.MuiTabs-indicator': {
            backgroundColor: textColorInactive,
            display: displayView,
          },
          '.MuiButtonBase-root.Mui-selected': {
            backgroundColor: '#97AABB',
            borderRadius: '10px',
          },
        }}
      >
        {(footerMenuLinks || DEFAULT_FOOTER_MENU)
          .filter((l: LinkItemType) => isClient || l.title !== 'ארנק')
          .map((link: LinkItemType, idx: number) => (
            <Tab
              key={idx}
              LinkComponent={Box}
              value={idx}
              icon={
                link.imageUrl
                  ? getIconElementByURL(link.imageUrl)
                  : getDefaultIconByTitle(link.title)
              }
              label={link.title}
              sx={{
                fontSize: fontSizeLabel,
                fontWeight: 600,
                pb: 0.5,
              }}
              iconPosition="top"
              onClick={() => {
                if (link.path === '/wallet' && !isAuthenticated) {
                  dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload: NAME_LOGIN_POPUP });
                } else {
                  router.push(
                    !isClient && link.path.includes('/history') ? '/supplier-history' : link.path
                  );
                }
              }}
            />
          ))}
      </Tabs>
    </Box>
  );
}
